@font-face {
  font-family: 'iconfont';  /* project id 2066400 */
  src: url('//at.alicdn.com/t/font_2066400_3r9fqfesuph.eot');
  src: url('//at.alicdn.com/t/font_2066400_3r9fqfesuph.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_2066400_3r9fqfesuph.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_2066400_3r9fqfesuph.woff') format('woff'),
  url('//at.alicdn.com/t/font_2066400_3r9fqfesuph.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_2066400_3r9fqfesuph.svg#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  //&::before {
  //  font-size: 18px;
  //  line-height: 1;
  //  vertical-align: middle;
  //}
  //&:empty::before {
  //  margin: auto;
  //}
  &.reverse{
    //左右反向
    transform: rotateY(180deg);
  }
}

.iconfont-home:before {
  content: "\e674"
}
.iconfont-my:before {
  content: "\eb9e"
}
.iconfont-my-fill:before {
  content: "\ebaf"
}
.iconfont-chat:before {
  content: "\eb98"
}
.iconfont-chat-fill:before {
  content: "\ebac"
}
.iconfont-contact:before {
  content: "\eb90"
}
.iconfont-contact-fill:before {
  content: "\eba4"
}
.iconfont-application:before {
  content: "\eba1"
}
.iconfont-link:before {
  content: "\eb97"
}
.iconfont-add:before {
  content: "\eb8f"
}
.iconfont-more:before {
  content: "\eba2"
}
.iconfont-round-add:before {
  content: "\e6d9"
}
.iconfont-keyboard:before {
  content: "\e71b"
}
.iconfont-sound:before {
  content: "\e77b"
}
.iconfont-sounding:before {
  content: "\e6e9"
}
.iconfont-pic:before {
  content: "\e69b"
}
.iconfont-form:before {
  content: "\e699"
}
.iconfont-gst:before {
  content: "\e63e"
}
.iconfont-profile:before {
  content: "\e91c"
}
